<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb
      :title="page.title"
      :breadcrumbs="breadcrumbs"
    />
    <v-row>
      <v-col cols="12" sm="12" lg="12">
        <v-card class="mb-7">
          <v-card-text class="pa-5 pb-0">
            <div class="d-sm-flex align-center">
              <div>
                <h2 class="font-weight-regular title">Actualizar nota</h2>
              </div>
            </div>
          </v-card-text>
          <v-card-text class=" border-bottom">
            <TheNoteForm
              v-if="noteLoaded"
              :currentNote="currentNote"
              @formValidated="doUpdateNote($event)"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TheNoteForm from "@/components/dashboardComponents/TheNoteForm"
import axios from 'axios'
import { mapState, mapActions } from 'vuex'

export default {
  name: "Update",
  components: {
    TheNoteForm
  },
  data: () => ({
    page: {
      title: "Notas"
    },
    currentNote: {},
    initial: null,
    breadcrumbs: [
      {
        text: "Home",
        disabled: false,
        href: "/dashboard/index/"
      },
      {
        text: "Notas",
        disabled: false,
        href: "/dashboard/notes/"
      },
      {
        text: "Actualizar Nota",
        disabled: true
      }
    ],
    noteLoaded: false,
    updated: false
  }),
  methods: {
    ...mapActions('notes', ['fetchTopics']),
    async doUpdateNote (note) {
      try {
        const payload = {
          titulo: note.titulo,
          temas: note.temas.map(item => item.id),
          resumen: note.resumen,
          cuerpo: note.cuerpo,
          pie_1: note.pie_1,
          pie_2: note.pie_2
        }
        await axios.put(`api/admin/notas/notas/${note.id}/`, payload)
        await this.fetchNote()
        await this.$root.$confirm({
          title: 'Mensaje',
          message: 'Nota actualizada.'
        })
        this.updated = true
      } catch (e) {
        await this.$root.$confirm({
          title: '¡Ups!',
          message: 'Ha ocurrido un error inesperado. Inténtelo de nuevo en unos minutos.'
        })
      }
    },
    async fetchNote () {
      const response = await axios.get(`api/admin/notas/notas/${this.$route.params.id}/`)
      this.currentNote = response.data
      this.initial = { ...this.currentNote }
    },
    testChanges () {
      let test = []
      for (const i of Object.keys(this.initial)) {
        test.push(this.initial[i] === this.currentNote[i])
      }
      const count = test.filter(Boolean).length
      return count
    }
  },
  computed: {
    ...mapState('notes', ['topics'])
  },
  async beforeRouteLeave (to, from, next) {
    const changesNotUpdated = await this.testChanges()
    let limit = 7
    if (this.updated) {
      limit = 7
    } else {
      limit = 6
    }
    if (changesNotUpdated === limit) {
      next()
    } else {
      this.$root.$confirm({
        title: '!Ups',
        message: `Primero debes guardar los cambios realizados `,
        acceptText: 'Aceptar'
      })
    }
  },
  async created () {
    await this.fetchTopics()
    if (this.$route.name === 'UpdateNote') {
      await this.fetchNote()
      this.currentNote.temas = this.topics.filter(item => this.currentNote.temas.includes(item.id))
      this.noteLoaded = true
    }
  }
}
</script>

<style lang="scss">
</style>
